<template>
  <v-container
    id="regular-tables-view"
    fluid
    tag="section"
  >
    <material-card
      icon="mdi-clipboard-text"
      icon-small
      title="SOS Item Type Management"
      color="accent"
    />
    <v-dialog
      v-model="itemTypeDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="4"
            >
              <v-text-field
                v-model="editItemType.name"
                label="Type Name *"
              >
              </v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-select
                v-model="editItemType.item_category_id"
                :items="itemCategoryList"
                item-value="id"
                item-text="full_name"
                label="Category of Type *"
              >
              </v-select>
            </v-col>
            <v-col
              cols="12"
              md="4"
            >
              <v-checkbox
                v-model="editItemType.request_check"
                label="Request Check After Testing *"
              >
              </v-checkbox>
            </v-col>
            <v-col
              cols="12"
            >
              <v-textarea
                v-model="editItemType.description"
                rows="3"
                clearable
                label="Description"
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            min-width="120px"
            text
            @click="closeItemTypeEdit()"
          >
            Cancel
          </v-btn>
          <v-btn
            min-width="120px"
            text
            @click="updateItemType()"
            v-if="editItemType.id > 0"
          >
            Update
          </v-btn>
          <v-btn
            min-width="120px"
            text
            @click="saveItemType()"
            v-else
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDeleteDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">
            Are you sure you want delete {{ this.editItemType.name }} ?
          </span>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            min-width="120px"
            text
            @click="closeItemTypeEdit()"
          >
            Cancel
          </v-btn>
          <v-btn
            min-width="120px"
            text
            color="red"
            @click="deleteItemType()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-data-table
      :headers="itemTypeDataHeader"
      :items="itemTypeData"
      class="elevation-1"
      sort-by="id"
    >
      <template v-slot:top>
        <v-toolbar
          flat
        >
          <v-toolbar-title>Item List</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="mb-2"
            @click="itemTypeDialog = true"
          >
            New Type
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          class="mr-2"
          @click="loadItemType(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click="confirmDelete(item)"
        >
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'
  import ItemTypeApi from '@/api/item-type'
  import ItemCategoryApi from '@/api/item-category'
  export default {
    name: 'ItemType',
    computed: {
      formTitle () {
        return this.editItemType.id > 0 ? 'New Item Type' : 'Edit Item Type'
      },
    },
    data () {
      return {
        itemCategoryList: [],
        itemTypeData: [],
        itemTypeDataHeader: [
          {
            text: 'id',
            align: 'start',
            sortable: true,
            value: 'id',
          },
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Category', value: 'item_category.name', sortable: true },
          { text: 'Request Check', value: 'request_check', sortable: true },
          { text: 'Actions', value: 'actions', sortable: false },
        ],
        itemTypeDialog: false,
        confirmDeleteDialog: false,
        defaultItemType: {
          id: 0,
          name: '',
          item_category_id: '',
          description: '',
          request_check: false,
        },
        editItemType: {
          id: 0,
          name: '',
          item_category_id: '',
          description: '',
          request_check: false,
        },
      }
    },
    created () {
      this.loadItemTypeData()
    },
    watch: {
      itemTypeDialog (newVal, oldVal) {
        if (newVal === true) this.loadItemCategoryList()
      },
    },
    methods: {
      loadItemCategoryList (keyword = 'all') {
        ItemCategoryApi.quickSearch(keyword).then(response => {
          this.itemCategoryList = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadItemTypeData () {
        ItemTypeApi.index().then(response => {
          this.itemTypeData = response.data.data
        }).catch(error => {
          console.log(error)
        })
      },
      loadItemType (item) {
        ItemTypeApi.show(item.id).then(response => {
          this.editItemType = response.data.data
          this.itemTypeDialog = true
        }).catch(error => {
          console.log(error)
        })
      },
      saveItemType () {
        ItemTypeApi.store(this.editItemType).then(response => {
          this.closeItemTypeEdit()
          this.loadItemTypeData()
        }).catch(error => {
          console.log(error)
        })
      },
      updateItemType () {
        ItemTypeApi.update(this.editItemType).then(response => {
          this.closeItemTypeEdit()
          this.loadItemTypeData()
        }).catch(error => {
          console.log(error)
        })
      },
      confirmDelete (item) {
        this.editItemType = item
        this.confirmDeleteDialog = true
      },
      deleteItemType () {
        ItemTypeApi.delete(this.editItemType.id).then(response => {
          this.closeItemTypeEdit()
          this.loadItemTypeData()
        }).catch(error => {
          console.log(error)
        })
      },
      closeItemTypeEdit () {
        this.editItemType = Object.assign({}, this.defaultItemType)
        this.itemTypeDialog = false
        this.confirmDeleteDialog = false
      },
    },
  }
</script>

<style scoped>

</style>
